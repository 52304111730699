import { User } from "@sentry/nextjs";
import { BaseOrganization, BaseUser, Organization } from "types/models";
import { BaseJobPost, JobPost } from "types/models";
import { z } from "zod";

import { UUID_REGEX } from "./constants";

export const mapToSelectOption = (data, labelKey, valueKey) => {
  return data.map((r) => ({ label: r[labelKey], value: r[valueKey] }));
};

export const isUUID = (str: string) => {
  return UUID_REGEX.test(str);
};

export const isValidEmail = (email: string) => {
  try {
    const emailSchema = z.string().email();
    emailSchema.parse(email);
    return true;
  } catch (error) {
    return false;
  }
};

export const getConnectorName = (user?: User | BaseUser, fullName?: boolean) => {
  if (!user) {
    return "";
  }
  if (user.brand_name && user.brand_name.length > 0) {
    return user.brand_name;
  }
  if (fullName) {
    return user.full_name;
  }
  return user.first_name;
};

export const getJobPostLocation = (jobPost: JobPost | BaseJobPost, fullLocation?: boolean) => {
  if (jobPost?.job_location_type === "REMOTE") {
    return "Remote";
  }

  if (fullLocation && jobPost?.job_location && jobPost?.job_location?.street_address) {
    return `${jobPost.job_location.street_address}, ${jobPost.job_location.city}, ${jobPost.job_location.state}`;
  }

  if (jobPost?.job_location) {
    return `${jobPost.job_location.city}, ${jobPost.job_location.state}`;
  }

  return "";
};

export const getOrganizationLocation = (organization: Organization | BaseOrganization, fullLocation?: boolean) => {
  if (fullLocation && organization?.primary_location) {
    return `${organization.primary_location.street_address}, ${organization.primary_location.city}, ${organization.primary_location.state}`;
  }

  if (organization?.primary_location) {
    return `${organization.primary_location.city}, ${organization.primary_location.state}`;
  }

  return "";
};

export const stripHtml = (html: string) => {
  return html.replace(/<[^>]+>/g, "");
};
