import Link from "next/link";
import { useRouter } from "next/router";

import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { UserRole } from "types/userRole";

import { OrganizationSelect } from "components/organizations/organization-select";
import { Button, Text } from "components/ui";
import NavTab from "components/ui/nav-tab";

import { useApp } from "@/lib/context/app-context";
import { getMarketingNavItems, getNavItems } from "@/lib/nav-items";
import { getUserDefaultPage } from "@/lib/user-helpers";

import { navigation } from "./footer";
import { UserDropdown } from "./user-dropdown";

const NavbarOpenItem = ({ name, href, handleHover }) => {
  return (
    <div>
      <Link href={href} onMouseEnter={(e) => handleHover(e, true)} onMouseLeave={(e) => handleHover(e, false)}>
        <Text
          variant="h1"
          className="navbar-open-item font-800 !font-['Dragon'] !text-[80px] uppercase !tracking-[3px] text-white opacity-100 transition-opacity duration-300 md:!text-[104px]"
        >
          {name}
        </Text>
      </Link>
    </div>
  );
};

const LogoSVG = ({ className }) => (
  <svg id="navbar-logo" viewBox="0 0 148 40" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="navbar-logo-animated">
      {/* <!-- <path id="boldZ" d="M0 32.77V39.4464H34.8039V32.2136H26.9597L25.3463 34.4391L24.5118 34.0496L34.8039 7.23276V0.556366H0V7.78914H7.34353L8.95688 5.56366L9.79138 5.95312L0 32.77Z" fill="#FF3F15"/> --> */}
      <path
        id="navbar-logo-letterZ"
        d="M16 39.45V32.7735L25.8935 5.95674L25.0503 5.56729L23.4201 7.79276H16V0.559998H35V7.23639L24.6006 34.0532L25.4438 34.4427L27.074 32.2172H35V39.45H16Z"
        fill="currentColor"
      />
    </g>
    <path
      id="navbar-logo-letterR"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.695 0.556396H139.043C141.416 0.556396 143.289 1.26108 144.662 2.67045C146.071 4.04272 146.776 5.9157 146.776 8.28936V12.3506C146.776 14.7242 146.182 16.5972 144.995 17.9695C143.846 19.3417 141.936 20.1021 139.265 20.2504V20.918C142.047 21.0664 144.031 21.7525 145.218 22.9764C146.442 24.2003 147.054 26.129 147.054 28.7622V39.4437H138.709V26.2031C138.709 25.3501 138.616 24.8123 138.431 24.5898C138.282 24.3302 138.023 24.2003 137.652 24.2003H137.04V39.4437H128.695V0.556396ZM137.652 16.9681C138.023 16.9681 138.282 16.8568 138.431 16.6343C138.616 16.3747 138.709 15.8554 138.709 15.0766V9.68018C138.709 8.90132 138.616 8.40062 138.431 8.17809C138.282 7.91848 138.023 7.78867 137.652 7.78867H137.04V16.9681H137.652Z"
      fill="currentColor"
    />
    <path
      id="navbar-logo-letterO"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.289 39.4993C115.402 39.8331 116.477 40 117.516 40C118.591 40 119.667 39.8331 120.742 39.4993C121.818 39.2026 122.801 38.7019 123.691 37.9972C124.581 37.2925 125.304 36.3653 125.861 35.2156C126.417 34.0658 126.695 32.675 126.695 31.0431V8.95689C126.695 7.32499 126.417 5.93417 125.861 4.78442C125.304 3.63468 124.581 2.70747 123.691 2.00278C122.801 1.2981 121.818 0.797405 120.742 0.500694C119.667 0.166897 118.591 0 117.516 0C116.477 0 115.402 0.166897 114.289 0.500694C113.213 0.797405 112.231 1.2981 111.34 2.00278C110.45 2.70747 109.727 3.63468 109.171 4.78442C108.614 5.93417 108.336 7.32499 108.336 8.95689V31.0431C108.336 32.675 108.614 34.0658 109.171 35.2156C109.727 36.3653 110.45 37.2925 111.34 37.9972C112.231 38.7019 113.213 39.2026 114.289 39.4993ZM116.792 7.67733C116.867 7.38062 117.108 7.23227 117.516 7.23227C117.924 7.23227 118.165 7.38062 118.239 7.67733C118.313 7.93695 118.35 8.43764 118.35 9.17942V30.8206C118.35 31.5994 118.313 32.1187 118.239 32.3783C118.165 32.6379 117.924 32.7677 117.516 32.7677C117.108 32.7677 116.867 32.6379 116.792 32.3783C116.718 32.1187 116.681 31.5994 116.681 30.8206V9.17942C116.681 8.43764 116.718 7.93695 116.792 7.67733Z"
      fill="currentColor"
    />
    <path
      id="navbar-logo-letterL"
      d="M100.217 0.556305V32.2114H106.336V39.4436H91.872V0.556305H100.217Z"
      fill="currentColor"
    />
    <path
      id="navbar-logo-letterT"
      d="M85.5327 7.78858H89.872V0.556305H72.8484V7.78858H77.1877V39.4436H85.5327V7.78858Z"
      fill="currentColor"
    />
    <path
      id="navbar-logo-letterT_2"
      d="M70.8484 7.78858H66.509V39.4436H58.1641V7.78858H53.8248V0.556305H70.8484V7.78858Z"
      fill="currentColor"
    />
    <path
      id="navbar-logo-letterE"
      d="M45.1488 15.6328V7.78858H51.6579V0.556305H36.8039V39.4436H51.8248V32.2114H45.1488V22.8651H50.3783V15.6328H45.1488Z"
      fill="currentColor"
    />
  </svg>
);

interface NavbarProps {
  fixed?: boolean;
  hideLinks?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ fixed = false, hideLinks = false }) => {
  const { user, organization, handleEmployerOnboardingDialogOpen } = useApp();
  const router = useRouter();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const updateBorderWidth = () => {
      if (window.scrollY < 50) {
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", updateBorderWidth);

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", updateBorderWidth);
  }, []);

  const marketingLinks = getMarketingNavItems(router);

  useEffect(() => {
    document.body.style.overflow = isPopoverOpen ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [isPopoverOpen]);

  const handleHover = (e, enter) => {
    if (enter) {
      // On mouse enter, add 'not-hovered-opacity-50' to all other a tags
      document.querySelectorAll(".navbar-open-item").forEach((el) => {
        if (el !== e.target) {
          el.classList.add("not-hovered-opacity-50");
        }
      });
    } else {
      // On mouse leave, remove 'not-hovered-opacity-50' from all a tags
      document.querySelectorAll(".navbar-open-item").forEach((el) => {
        el.classList.remove("not-hovered-opacity-50");
      });
    }
  };

  const isZettlorDotComOrCompanies = router.asPath === "/" || router.asPath === "/companies";

  return (
    <Popover
      as="header"
      className={classNames("z-10 w-full bg-zettlor-new-off-white", fixed ? "md:fixed md:top-0" : "")}
    >
      {({ open }) => {
        // Sync internal state with Popover state
        if (isPopoverOpen !== open) {
          setIsPopoverOpen(open);
        }

        return (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6">
              <div className="flex items-center justify-between py-3 md:py-4">
                <div className="flex items-center justify-start lg:w-0 lg:flex-1">
                  <div className="mr-2 flex items-center md:hidden">
                    <Popover.Button
                      id="menu-button-mobile"
                      className="font-520 inline-flex items-center justify-center text-lg text-zettlor-new-black"
                    >
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon className="h-7 w-7" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <Link href={!!user ? getUserDefaultPage({ user }) : "/"}>
                    <LogoSVG className="max-h-7 w-24 text-zettlor-new-orange md:max-h-10 md:w-36" />
                  </Link>
                  {user?.selected_role === UserRole.Employer && !!organization && (
                    <>
                      <div className="hidden text-zettlor-new-black/20 sm:block">
                        <svg
                          fill="none"
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          className="h-5 w-5 sm:h-7 sm:w-7"
                        >
                          <path d="M16.88 3.549L7.12 20.451"></path>
                        </svg>
                      </div>
                      <div className="hidden sm:ml-2 sm:block">
                        <OrganizationSelect />
                      </div>
                    </>
                  )}
                </div>

                {isZettlorDotComOrCompanies && (
                  <div className="hidden md:flex justify-center flex-1">
                    <NavTab />
                  </div>
                )}

                {!hideLinks && (
                  <div className="flex items-center space-x-2 lg:w-0 lg:flex-1 justify-end">
                    <div className="hidden items-center space-x-2 md:flex">
                      {!!user ? (
                        getNavItems(router, user).map((item) => {
                          return (
                            <Link key={item.href} href={item.href}>
                              <Text
                                variant="h6"
                                className={classNames(
                                  "font-520 inline-flex items-center justify-center space-x-1.5 rounded-lg px-4 py-1.5 text-base text-zettlor-new-black hover:bg-zettlor-new-black/[0.08]",
                                  item.current ? "bg-zettlor-new-black/[0.08]" : "",
                                )}
                              >
                                {item.icon}
                                <span>{item.name}</span>
                              </Text>
                            </Link>
                          );
                        })
                      ) : (
                        <Popover.Button
                          id="menu-button-desktop"
                          className="font-520 inline-flex items-center justify-center space-x-1.5 rounded-lg px-3 py-[7px] text-lg text-zettlor-new-black hover:bg-zettlor-new-black/[0.08]"
                        >
                          <Bars3Icon className="h-auto w-5 stroke-[3]" aria-hidden="true" />
                          <div>
                            <span className="sr-only">Open menu</span>
                            <Text variant="h5" className="!text-lg !leading-[22px]">
                              Menu
                            </Text>
                          </div>
                        </Popover.Button>
                      )}
                    </div>
                    {user ? (
                      <UserDropdown />
                    ) : (
                      <Popover.Group as="nav" className="flex space-x-2">
                        <Button
                          onClick={() => handleEmployerOnboardingDialogOpen("publish")}
                          className="transition-colors delay-50 ease-in-out text-lg font-520 leading-[20px] relative appearance-none focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-[#6D82CC] px-3 py-[7px] items-center justify-center inline-flex rounded-lg hover:bg-zettlor-new-orange/[0.88] border border-zettlor-new-orange bg-zettlor-new-orange text-white flex space-x-2"
                        >
                          <span>Post a Job</span>
                        </Button>
                      </Popover.Group>
                    )}
                  </div>
                )}
              </div>

              {isZettlorDotComOrCompanies && (
                <div className="md:hidden -mt-2 pb-2 flex justify-center">
                  <NavTab />
                </div>
              )}
            </div>
            <div
              className={classNames(
                "border-b border-zettlor-new-black/[0.12] mx-auto w-full transition-all duration-200",
                isScrolled ? "max-w-full" : "max-w-7xl",
              )}
            />

            <Transition
              as={Fragment}
              enter="duration-300 ease-out"
              enterFrom="opacity-0" // scale-50"
              enterTo="opacity-100" // scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100" // scale-100"
              leaveTo="opacity-0" // scale-50"
            >
              <Popover.Panel focus className="fixed inset-x-0 top-0 z-50 h-full w-full origin-top-right transition">
                <div className="flex h-full w-full flex-col justify-between overflow-hidden bg-zettlor-new-orange">
                  <div className="">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6">
                      <div className="flex items-center justify-between border-b border-white py-6 md:space-x-10">
                        <div className="flex items-center justify-start lg:w-0 lg:flex-1">
                          <div className="mr-2 flex items-center md:hidden">
                            <Popover.Button
                              id="menu-button-mobile"
                              className="font-520 inline-flex items-center justify-center text-lg text-white"
                            >
                              <span className="sr-only">Open menu</span>
                              <XMarkIcon className="h-7 w-7" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                          <LogoSVG className="max-h-7 w-24 text-white md:max-h-10 md:w-36" />
                        </div>
                        <div className="flex items-center space-x-2">
                          <Popover.Button className="hidden items-center justify-center space-x-1.5 rounded-lg px-3 py-[7px] text-white outline-none hover:bg-zettlor-new-black/[0.08] md:inline-flex">
                            <XMarkIcon className="h-auto w-5 stroke-[3]" aria-hidden="true" />
                            <div>
                              <span className="sr-only">Close menu</span>
                              <Text variant="h5" className="!text-lg !leading-[22px]">
                                Close
                              </Text>
                            </div>
                          </Popover.Button>
                          {!user && (
                            <Button
                              onClick={() => handleEmployerOnboardingDialogOpen("publish")}
                              className="transition-colors delay-50 ease-in-out text-lg font-520 leading-[20px] relative appearance-none focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-[#6D82CC] px-3 py-[7px] items-center justify-center inline-flex rounded-lg hover:bg-zettlor-new-orange/[0.88] border border-zettlor-new-orange bg-zettlor-new-orange text-white flex space-x-2"
                            >
                              <span>Post a Job</span>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mx-auto max-w-7xl space-y-3 px-4 pt-5 sm:px-6">
                      {marketingLinks.map((item) => (
                        <Fragment key={item.href}>
                          <NavbarOpenItem name={item.name} href={item.href} handleHover={handleHover} />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="mx-auto block w-full max-w-7xl items-center justify-between space-y-3 border-t border-white px-4 py-6 text-center sm:px-6 md:flex md:space-y-0 md:text-left">
                    <div>
                      <Text className="text-white" weight="font-480" variant="b3">
                        © 2024 Zettlor, Inc. All rights reserved.
                      </Text>
                    </div>
                    <div className="flex items-center justify-center space-x-6">
                      {navigation.social.map((item) => (
                        <a key={item.name} href={item.href} className="text-white hover:text-white">
                          <span className="sr-only">{item.name}</span>
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </a>
                      ))}
                    </div>
                    <div>
                      <Text className="text-white" weight="font-480" variant="b3">
                        Call or text us:{" "}
                        <a href="tel:13852131571" className="underline">
                          (385) 213-1571
                        </a>
                      </Text>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

export default Navbar;
