import Link from "next/link";
import { useRouter } from "next/router";

import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment } from "react";

import { Button, ProfileImage, Text } from "components/ui";

import { useApp } from "@/lib/context/app-context";
import { useFeatureFlags } from "@/lib/context/feature-flag-context";
import { NavItem, getNavItems, getUserDropdownItems } from "@/lib/nav-items";

interface UserDropdownProps {}

export const UserDropdown: FC<UserDropdownProps> = () => {
  const app = useApp();
  const router = useRouter();
  const { user } = app;
  const featureFlags = useFeatureFlags();

  const userNavigation = getUserDropdownItems(app, featureFlags);

  return (
    <Menu as="div" className="relative ml-5">
      <div>
        <Menu.Button
          className={classNames(
            "transition-colors delay-50 ease-in-out text-lg font-520 leading-[20px] space-x-2",
            "relative appearance-none focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-[#6D82CC]",
            "px-3 py-[7px] items-center justify-center inline-flex rounded-lg",
            "hover:border-zettlor-new-black",
            "border border-zettlor-new-black/[0.08] bg-white text-zettlor-new-black",
          )}
        >
          <Text variant="b2" className="max-w-[100px] truncate font-semibold !leading-none">
            <span className="sr-only">Open user menu</span>
            {user?.first_name}
          </Text>
          <ProfileImage size="5" src={user?.profile_picture} alt={`${user?.first_name}'s profile image`} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right divide-y divide-zettlor-new-black/20 overflow-hidden rounded-lg border border-zettlor-new-black/20 bg-zettlor-new-off-white shadow-lg focus:outline-none">
          <div className="block md:hidden">
            {getNavItems(router, user).map((item: NavItem, j) => (
              <Menu.Item key={`nav-item-${j}`}>{({ active }) => userMenuItem(item, active)}</Menu.Item>
            ))}
          </div>
          {userNavigation
            .filter((f) => f.length > 0)
            .map((topLevel: NavItem[], i) => (
              <div key={`nav-item-top-${i}`}>
                {topLevel.map((item: NavItem, j) => (
                  <Menu.Item key={`nav-item-${i}-${j}`}>{({ active }) => userMenuItem(item, active)}</Menu.Item>
                ))}
              </div>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const userMenuItem = (item, active) => {
  if (item.onClick) {
    return (
      <Button
        key={item.name}
        onClick={item.onClick}
        variant="ghost"
        className={classNames(
          active ? "bg-zettlor-new-black/[0.08]" : "",
          "w-full text-zettlor-new-black !px-4 !py-3.5 space-x-2 !text-sm flex items-center !justify-start !rounded-none",
        )}
      >
        {item.icon && item.icon}
        <span className="text-left">
          <Text variant="b3" className="font-520">
            {item.name}
          </Text>
          {item.subText && (
            <Text variant="b3" className="text-left text-zettlor-new-black/80">
              {item.subText}
            </Text>
          )}
        </span>
      </Button>
    );
  }

  return (
    <Link
      key={item.name}
      href={item.href}
      className={classNames(
        active ? "bg-zettlor-new-black/[0.08]" : "",
        "text-zettlor-new-black px-4 py-3.5 space-x-2 text-sm flex items-center",
      )}
    >
      {item.icon && item.icon}
      <span className="text-left">
        <Text variant="b3" className="font-520">
          {item.name}
        </Text>
        {item.subText && (
          <Text variant="b3" className="text-left text-zettlor-new-black/80">
            {item.subText}
          </Text>
        )}
      </span>
    </Link>
  );
};
