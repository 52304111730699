import { FC } from "react";

import { Select } from "components/form-inputs/select";
import { OrganizationLogo } from "components/ui";

import { useApp } from "@/lib/context/app-context";

interface OrganizationSelectProps {}

export const OrganizationSelect: FC<OrganizationSelectProps> = () => {
  const { user, organization, setOrganizationByUuid } = useApp();

  const selectOptions = user?.organizations.map((c) => ({
    value: c.uuid,
    label: (
      <div className="flex w-32 items-center justify-start space-x-2 md:w-48" data-organization-name={c.name}>
        <OrganizationLogo src={c.logo} name={c.name} size="6" />
        <p className="truncate text-xs sm:text-sm">{c.name}</p>
      </div>
    ),
  }));

  if (user?.organizations.length === 1) {
    return (
      <div className="flex items-center justify-start space-x-2">
        <OrganizationLogo src={user?.organizations[0].logo} name={user?.organizations[0].name} size="6" />
        <p className="max-w-[90px] truncate text-xs sm:max-w-[140px] sm:text-sm">{user?.organizations[0].name}</p>
      </div>
    );
  }

  return (
    <Select
      // @ts-expect-error - labels are meant to be strings but ReactNodes work as well
      options={selectOptions}
      // @ts-expect-error - labels are meant to be strings but ReactNodes work as well
      value={selectOptions?.find((so) => so.value === organization?.uuid)}
      isSearchable={true}
      customStylingConfig={{
        searchCursorMargin: 32,
      }}
      filterOption={(option, search) => {
        // @ts-expect-error - labels are meant to be strings but ReactNodes work as well
        return option.label.props["data-organization-name"]?.toLowerCase().includes(search.toLowerCase());
      }}
      id="organization-select"
      onChange={(option) => setOrganizationByUuid(option.value)}
    />
  );
};
