import { NextRouter } from "next/router";

import {
  ArrowsRightLeftIcon,
  BellAlertIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  SparklesIcon,
  UserGroupIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import posthog from "posthog-js";
import { ReactNode } from "react";
import { User } from "types/models";
import { UserRole } from "types/userRole";

import { ContextProps } from "@/lib/context/app-context";
import { FeatureFlagsType } from "@/lib/context/feature-flag-context";

import ZettlorAuthService from "services/zettlor-auth-service";

import {
  CONNECTOR_ROLE_DESCRIPTION,
  EMPLOYER_DEMO_URL,
  EMPLOYER_ROLE_DESCRIPTION,
  LOG_IN_URL,
  PROFILE_URL,
  WORKER_ROLE_DESCRIPTION,
} from "./constants";
import { isEmployeeConnector } from "./user-helpers";

export interface NavItem {
  name: string | ReactNode;
  subText?: string;
  icon?: ReactNode;
  href?: string;
  onClick?: () => void;
  current?: boolean;
}

const iconLookup = {
  user: <UserIcon className="h-6 w-6" />,
  "user-plus": <UserPlusIcon className="h-6 w-6" />,
  "arrows-right-left": <ArrowsRightLeftIcon className="h-6 w-6" />,
  wallet: <CurrencyDollarIcon className="h-6 w-6" />,
  billing: <CreditCardIcon className="h-6 w-6" />,
  notifications: <BellAlertIcon className="h-6 w-6" />,
  settings: <Cog6ToothIcon className="h-6 w-6" />,
  sparkle: <SparklesIcon className="h-6 w-6" />,
  "user-group": <UserGroupIcon className="h-6 w-6" />,
  globe: <GlobeAltIcon className="h-6 w-6" />,
  subscription: <CreditCardIcon className="h-6 w-6" />,
};

const SWITCH_ROLE_INDEX = 0;
const ACCOUNT_ITEM_INDEX = 1;

const zettlorAuthService = new ZettlorAuthService();
export const getUserDropdownItems = (app: ContextProps, _featureFlags: Partial<FeatureFlagsType>): NavItem[][] => {
  const { user, organization, connectorOrganization, switchRole } = app;
  let tabs: NavItem[][] = [
    // Switch role items
    [],
    // Account items
    [],
    // Logout
    [
      {
        name: "Sign out",
        onClick: async () => {
          try {
            // Reset the anonymousId incase multiple users are using the same device
            posthog?.reset();
          } catch {}
          await zettlorAuthService.logout();
          window.location.href = LOG_IN_URL;
        },
      },
    ],
  ];

  // ===================
  //     Connector
  // ===================
  if (user?.selected_role === UserRole.Connector) {
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["billing"],
      name: "Billing",
      href: "/account/billing",
    });
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["wallet"],
      name: "Wallet",
      href: "/account/wallet",
    });
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["globe"],
      name: "My network",
      href: "/network",
    });
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["user-group"],
      name: "Activity",
      href: "/job-applications",
    });
  }

  // ===================
  //     Employer
  // ===================
  if (user?.selected_role === UserRole.Employer) {
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["settings"],
      name: `${organization?.name || "Organization"} settings`,
      href: "/organizations/settings",
    });
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["subscription"],
      name: "Subscription",
      href: "/organizations/settings/subscription",
    });
  }

  // ===================
  //     Worker
  // ===================
  if (user?.selected_role === UserRole.Worker) {
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["wallet"],
      name: "Wallet",
      href: "/account/wallet",
    });
  }

  if (user) {
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["notifications"],
      name: "Notifications",
      href: "/account/notifications",
    });
    tabs[ACCOUNT_ITEM_INDEX].unshift({
      icon: iconLookup["user"],
      name: "Profile",
      href: PROFILE_URL,
    });
  }

  // ===================
  //   Role switches
  // ===================
  // Worker role changes
  if (user?.selected_role === UserRole.Worker) {
    if (user?.is_connector) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["arrows-right-left"],
        name: "Switch to connector account",
        subText: CONNECTOR_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Connector),
      });
    } else {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["user-plus"],
        name: "Create connector account",
        subText: CONNECTOR_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Connector),
      });
    }
    if (user?.is_employer) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["arrows-right-left"],
        name: "Switch to employer account",
        subText: EMPLOYER_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Employer),
      });
    } else {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["user-plus"],
        name: "Create employer account",
        subText: EMPLOYER_ROLE_DESCRIPTION,
        href: EMPLOYER_DEMO_URL,
      });
    }
  }

  // Connector role changes
  if (user?.selected_role === UserRole.Connector) {
    if (user?.is_worker) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["arrows-right-left"],
        name: "Switch to candidate account",
        subText: WORKER_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Worker),
      });
    } else if (!isEmployeeConnector(connectorOrganization)) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["user-plus"],
        name: "Create candidate account",
        subText: WORKER_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Worker),
      });
    }
    if (user?.is_employer) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["arrows-right-left"],
        name: "Switch to employer account",
        subText: EMPLOYER_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Employer),
      });
    } else if (!isEmployeeConnector(connectorOrganization)) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["user-plus"],
        name: "Create employer account",
        subText: EMPLOYER_ROLE_DESCRIPTION,
        href: EMPLOYER_DEMO_URL,
      });
    }
  }

  // Employer role changes
  if (user?.selected_role === UserRole.Employer) {
    if (user?.is_worker) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["arrows-right-left"],
        name: "Switch to candidate account",
        subText: WORKER_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Worker),
      });
    } else {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["user-plus"],
        name: "Create candidate account",
        subText: WORKER_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Worker),
      });
    }
    if (user?.is_connector) {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["arrows-right-left"],
        name: "Switch to connector account",
        subText: CONNECTOR_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Connector),
      });
    } else {
      tabs[SWITCH_ROLE_INDEX].unshift({
        icon: iconLookup["user-plus"],
        name: "Create connector account",
        subText: CONNECTOR_ROLE_DESCRIPTION,
        onClick: () => switchRole(UserRole.Connector),
      });
    }
  }

  return tabs;
};

export const getNavItems = (router: NextRouter, user?: User): NavItem[] => {
  const tabs: NavItem[] = [];

  // ===================
  //     Employer
  // ===================
  if (user?.selected_role === UserRole.Employer) {
    tabs.push({
      name: "Job posts",
      href: "/job-posts",
      current: router.pathname.endsWith("/job-posts"),
    });
    tabs.push({
      name: "Candidates",
      href: "/job-applications",
      current: router.pathname.endsWith("/job-applications"),
    });
  }

  // ===================
  //     Connector
  // ===================
  if (user?.selected_role === UserRole.Connector) {
    // TODO - if current user is an employee to an organization, add the org to the query params of search
    tabs.push({
      name: "Dashboard",
      href: "/dashboard",
      current: router.pathname.includes("/dashboard"),
    });
    tabs.push({
      name: "Search",
      href: "/search",
      current: router.pathname.includes("/search"),
    });
    tabs.push({
      name: "Active contracts",
      href: "/connector-job-posts",
      current: router.pathname.endsWith("/connector-job-posts"),
    });
  }

  // ===================
  //     Worker
  // ===================
  if (user?.selected_role === UserRole.Worker) {
    tabs.push({
      name: "Chat",
      href: `/chat`,
      current: router.pathname.endsWith("/chat"),
    });
    tabs.push({
      name: "Applications",
      href: `/job-applications`,
      current: router.pathname.endsWith("/job-applications"),
    });
    tabs.push({
      name: "Talent networks",
      href: `/following`,
      current: router.pathname.endsWith("/following"),
    });
    tabs.push({
      name: "Recommended jobs",
      href: `/recommendations`,
      current: router.pathname.endsWith("/recommendations"),
    });
  }

  return tabs;
};

export const getMarketingNavItems = (router: NextRouter): NavItem[] => {
  const tabs: NavItem[] = [];

  tabs.push({
    name: "FAQ",
    href: "/resources/faq",
    current: router.pathname.endsWith("/faq"),
  });
  tabs.push({
    name: "Blog",
    href: "/blog",
    current: router.pathname.endsWith("/blog"),
  });
  tabs.push({
    name: "Careers",
    href: "https://www.zettlor.com/o/@zettlor",
  });
  tabs.push({
    name: "Partnerships",
    href: "mailto:a@zettlor.com?subject=Zettlor+Partnerships",
  });
  // tabs.push({
  //   name: "Job description generator",
  //   href: AI_JOB_DESCRIPTION_GENERATOR_URL,
  //   current: router.pathname.endsWith("/ai-job-description-generator"),
  // });

  return tabs;
};
