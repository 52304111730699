import Link from "next/link";
import { useRouter } from "next/router";

import { motion } from "framer-motion";
import * as React from "react";

export default function NavTab() {
  const router = useRouter();
  const tabs = [
    { value: "candidates", label: "Candidates", href: "/" },
    { value: "companies", label: "Companies", href: "/companies" },
  ];

  const activeTab = router.pathname === "/companies" ? "companies" : "candidates";

  return (
    <div className="relative bg-gray-200 p-0.5 rounded-full inline-flex">
      {tabs.map((tab) => (
        <Link key={tab.value} href={tab.href} className="flex-1">
          <button
            className={`relative px-4 py-1.5 rounded-full text-sm font-medium transition-colors duration-200 whitespace-nowrap ${
              activeTab === tab.value ? "text-gray-900" : "text-gray-600 hover:text-gray-800"
            }`}
          >
            {activeTab === tab.value && (
              <motion.div
                layoutId="activeTab"
                className="absolute inset-0 bg-white rounded-full shadow-sm"
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              />
            )}
            <span className="relative z-10">{tab.label}</span>
          </button>
        </Link>
      ))}
    </div>
  );
}
