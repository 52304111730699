import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";

import { Button, Text } from "components/ui";

import { AI_JOB_DESCRIPTION_GENERATOR_URL } from "@/lib/constants";
import { isValidEmail } from "@/lib/helpers";

export const navigation = {
  support: [
    { name: "FAQ", href: `/resources/faq` },
    { name: "Blog", href: "/blog" },
    { name: "(385) 213-1571", href: "tel:3852131571" },
  ],
  company: [
    { name: "Careers", href: "https://www.zettlor.com/o/@zettlor" },
    { name: "Partners", href: "mailto:a@zettlor.com?subject=Zettlor+Partnerships" },
  ],
  product: [{ name: "Job description generator", href: AI_JOB_DESCRIPTION_GENERATOR_URL }],
  legal: [{ name: "Privacy", href: "https://www.zettlor.com/privacy" }],
  social: [
    // {
    //   name: "Facebook",
    //   href: "https://www.facebook.com/zettlor",
    //   icon: (props: any) => (
    //     <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
    //       <path d="M374.244,285.825l14.105,-91.961l-88.233,0l0,-59.677c0,-25.159 12.325,-49.682 51.845,-49.682l40.116,0l0,-78.291c0,0 -36.407,-6.214 -71.213,-6.214c-72.67,0 -120.165,44.042 -120.165,123.775l0,70.089l-80.777,0l0,91.961l80.777,0l0,222.31c16.197,2.541 32.798,3.865 49.709,3.865c16.911,0 33.511,-1.324 49.708,-3.865l0,-222.31l74.128,0Z" />
    //     </svg>
    //   ),
    // },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/zettlor",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
          <path d="M473.305,-1.353c20.88,0 37.885,16.533 37.885,36.926l0,438.251c0,20.393 -17.005,36.954 -37.885,36.954l-436.459,0c-20.839,0 -37.773,-16.561 -37.773,-36.954l0,-438.251c0,-20.393 16.934,-36.926 37.773,-36.926l436.459,0Zm-37.829,436.389l0,-134.034c0,-65.822 -14.212,-116.427 -91.12,-116.427c-36.955,0 -61.739,20.263 -71.867,39.476l-1.04,0l0,-33.411l-72.811,0l0,244.396l75.866,0l0,-120.878c0,-31.883 6.031,-62.773 45.554,-62.773c38.981,0 39.468,36.461 39.468,64.802l0,118.849l75.95,0Zm-284.489,-244.396l-76.034,0l0,244.396l76.034,0l0,-244.396Zm-37.997,-121.489c-24.395,0 -44.066,19.735 -44.066,44.047c0,24.318 19.671,44.052 44.066,44.052c24.299,0 44.026,-19.734 44.026,-44.052c0,-24.312 -19.727,-44.047 -44.026,-44.047Z" />
        </svg>
      ),
    },
    {
      name: "X",
      href: "https://x.com/zettlor",
      icon: (props: any) => (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="currentColor" {...props}>
          <path
            d="M0.367188 0L8.37099 11.4384L0.787977 20.3036H3.76655L9.70386 13.3441L14.5727 20.3036H22.3672L13.9955 8.31885L21.0916 0H18.1593L12.667 6.41538L8.18593 0H0.367188ZM4.69845 2.25596H7.00949L18.0381 18.0477H15.7469L4.69845 2.25596Z"
            fill="white"
          />
        </svg>
      ),
    },
    // {
    //   name: "Instagram",
    //   href: "https://www.instagram.com/zettlor_",
    //   icon: (props: any) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
  ],
};

const zetItSayings = [
  "your price",
  "your terms",
  "your next hire",
  "a new standard",
  "it, don’t sweat it",
  "your price",
  "your terms",
  "your next hire",
  "a new standard",
];

const FooterColumn = ({ title, links, handleHover }) => {
  return (
    <div>
      <div className="w-full border-b-2 border-white/40">
        <Text variant="h4" className="inline-block !text-xl uppercase text-white/40" weight="font-520">
          {title}
        </Text>
      </div>
      <ul role="list" className="mt-2 space-y-4 md:mt-5">
        {links.map((item) => (
          <li key={item.name} className="footer-column-item">
            <a
              href={item.href}
              className="font-500 text-2xl leading-[1.12] text-white opacity-100 transition-opacity duration-300"
              onMouseEnter={(e) => handleHover(e, true)}
              onMouseLeave={(e) => handleHover(e, false)}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const EmailSubscribe = () => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isValidEmail(email)) {
        return toast.error("Please enter a valid email address");
      }
      const formBody = `userGroup=${encodeURIComponent("Website signups")}&email=${encodeURIComponent(email)}`;
      const response = await fetch("https://app.loops.so/api/newsletter-form/clerbampg0027jp0fnma5m29m", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      });

      if (response.ok) {
        setSubscribed(true);
      } else {
        throw new Error("Failed to subscribe");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="w-full px-4">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center rounded-full bg-white/20 p-3 text-white">
          {!subscribed && (
            <>
              <input type="hidden" name="userGroup" value="Website signups" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email address"
                className="grow rounded-full border-none bg-transparent px-6 py-1 text-xl text-white outline-none placeholder:text-white/40 focus:shadow-none focus:ring-0 md:px-10 md:py-3 md:text-5xl"
              />
              <button className="font-480 hidden !rounded-full bg-white !py-6 px-7 !text-5xl !text-zettlor-new-orange hover:bg-white/80 md:block">
                Subscribe
              </button>
            </>
          )}
          {subscribed && (
            <div className="font-480 hidden w-full !rounded-full !bg-white !py-6 px-7 text-center !text-5xl !text-zettlor-new-orange md:block">
              Subscribed
            </div>
          )}
        </div>
      </form>
      <Button
        type="submit"
        className="mt-2 block w-full !rounded-full !bg-white !text-3xl !text-zettlor-new-orange md:hidden"
      >
        Subscribe
      </Button>
      <Text variant="h4" weight="font-500" className="mt-4 text-center text-white">
        Updates, resources, and tips delivered every so often.
      </Text>
    </div>
  );
};

export default function Footer() {
  const contentRef = useRef(null);
  const [contentWidth, setContentWidth] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentWidth(contentRef.current.offsetWidth);
    }
  }, []);

  const variants = useMemo(() => {
    return {
      initial: {
        x: 0,
      },
      animate: {
        x: -contentWidth,
        transition: {
          duration: 20,
          ease: "linear",
          repeat: Infinity,
        },
      },
    };
  }, [contentWidth]);

  const handleHover = (e, enter) => {
    if (enter) {
      // On mouse enter, add 'not-hovered-opacity-50' to all other a tags
      document.querySelectorAll(".footer-column-item a").forEach((el) => {
        if (el !== e.target) {
          el.classList.add("not-hovered-opacity-50");
        }
      });
    } else {
      // On mouse leave, remove 'not-hovered-opacity-50' from all a tags
      document.querySelectorAll(".footer-column-item a").forEach((el) => {
        el.classList.remove("not-hovered-opacity-50");
      });
    }
  };

  return (
    <footer className="pt-20" aria-labelledby="footer-heading" id="footer">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <img className="-mb-2 h-auto w-full md:-mb-4" src="/static/zettlor-logo-new-orange.svg" alt="Zettlor" />
      <div className="mx-auto bg-zettlor-new-orange p-8 px-6 md:p-16 md:px-12">
        <div className="mt-10 lg:mt-0">
          <EmailSubscribe />
        </div>
        <div className="flex items-center justify-center space-x-6 py-24 md:order-2">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-white hover:text-white">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="group space-y-8 md:grid md:grid-cols-4 md:gap-8 md:space-y-0">
          <FooterColumn title="Support" links={navigation.support} handleHover={handleHover} />
          <FooterColumn title="Product" links={navigation.product} handleHover={handleHover} />
          <FooterColumn title="Company" links={navigation.company} handleHover={handleHover} />
          <FooterColumn title="Legal" links={navigation.legal} handleHover={handleHover} />
        </div>
      </div>
      <div className="overflow-hidden bg-white py-4 pb-6">
        <motion.div className="flex space-x-8" initial="initial" animate="animate" variants={variants} ref={contentRef}>
          {zetItSayings.map((saying, i) => (
            <Text variant="h2" className="shrink-0 !text-[40px]" key={i}>
              <span className="text-zettlor-new-orange">Zet</span> {saying}
            </Text>
          ))}
        </motion.div>
      </div>
    </footer>
  );
}
